<template>
  <div class="">
    <button
      class="absolute bottom-0 right-0 mb-6 mr-6 rounded-full focus:outline-none focus:ring"
    >
      <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <circle fill="#20E682" cx="28" cy="28" r="28" />
          <path d="M14 14h28v28H14z" />
          <path
            stroke="#FFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M23.406 26.031L28 30.625l4.594-4.594M28 18.372v12.25"
          />
          <path
            d="M38.5 28.875v7.875a.875.875 0 01-.875.875h-19.25a.875.875 0 01-.875-.875v-7.875"
            stroke="#FFF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
    <h1 class="text-base font-bold text-gray-type-2">
      Your company has 2 admins
    </h1>
    <div class="w-full mt-6">
      <app-table :items="admins" :fields="adminsFields" class="mt-6">
        <template v-slot:actions="{ item }">
          <button @click="deleteAdmin(item)">
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path d="M0 0h20v20H0z" />
                <path
                  d="M14.167 5h4.166v1.667h-1.666V17.5c0 .46-.373.833-.834.833H4.167a.833.833 0 01-.834-.833V6.667H1.667V5h4.166V2.5c0-.46.373-.833.834-.833h6.666c.46 0 .834.373.834.833V5zM7.5 9.167v5h1.667v-5H7.5zm3.333 0v5H12.5v-5h-1.667zM7.5 3.333V5h5V3.333h-5z"
                  fill="#AC3535"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </button>
        </template>
      </app-table>
    </div>
    <modal :show="showDeleteAdmin">
      <div class="p-6">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="showDeleteAdmin = false"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Delete administrator
          </div>
        </div>
        <div class="mt-6">
          <p class="text-xs font-medium text-center text-gray-type-8">
            Deleting an administrator will disable their access to this
            acccount.
          </p>
          <div class="flex items-center justify-between mt-16 space-x-6">
            <button
              class="w-full h-12 py-4 font-bold text-center text-white rounded bg-gray-type-11"
              @click="showDeleteAdmin = false"
            >
              Cancel
            </button>
            <button
              class="w-full h-12 py-4 font-bold text-center text-white rounded bg-red-type-1"
              @click="showDeleteAdmin = false"
            >
              Delete Admin
            </button>
          </div>
        </div>
      </div>
    </modal>
    <modal :show="showInviteAdmin">
      <div class="p-6">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="showInviteAdmin = false"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Invite administrator
          </div>
        </div>
        <form class="mt-8">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="first-name"
                >First name</label
              >
              <input
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="first-name"
              />
            </div>
            <div class="w-full px-3 md:w-1/2">
              <label
                class="text-xs font-medium text-gray-type-3"
                for="last-name"
                >Last name</label
              >
              <input
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                type="text"
                id="last-name"
              />
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="email"
              >Email address</label
            >
            <div class="mt-2">
              <input
                type="email"
                id="email"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
            </div>
          </div>
          <div class="mt-6">
            <label
              class="text-xs font-medium text-gray-type-3"
              for="phone-number"
              >Phone number</label
            >
            <div class="mt-2">
              <input
                type="text"
                id="phone-number"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="password"
              >Password</label
            >
            <div class="relative mt-2">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
                @click="showPassword = !showPassword"
              >
                {{ showPassword ? "Hide" : "Show" }}
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="block w-full py-3 mt-20 font-bold text-center text-white rounded bg-green-type-1"
          >
            Add Admin
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import AppTable from "@/components/AppTable";

export default {
  components: {
    Modal,
    AppTable,
  },
  data() {
    return {
      showDeleteAdmin: false,
      selectedAdmin: null,
      showInviteAdmin: false,
      showPassword: false,
      newAdmin: {},
      admins: [
        {
          id: 1,
          name: "Bakare abdul",
          email: "Bakareabdul@gmail.com",
          role: "Admin",
          status: "Accepted",
        },
      ],
      adminsFields: [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "role", label: "Role" },
        { key: "status", label: "Status" },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.emitter.on("close", () => {
      this.showDeleteAdmin = false;
      this.showInviteAdmin = false;
    });
  },
  methods: {
    deleteAdmin(item) {
      this.selectedAdmin = item;
      this.showDeleteAdmin = true;
    },
  },
};
</script>